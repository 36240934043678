import { useMemo } from 'react';
import { UserSubscriptionStatus } from '@remento/types/user';
import dayjs from 'dayjs';

import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { genitiveCase } from '@/utils/genitiveCase';

export interface SubscriptionSettingsPreviewProps {
  subscriptionStatus: UserSubscriptionStatus;
  subscriptionEndDate: number;
  isOwner: boolean;
  ownerName: string;
}

export function SubscriptionSettingsPreview({
  subscriptionStatus,
  subscriptionEndDate,
  isOwner,
  ownerName,
}: SubscriptionSettingsPreviewProps) {
  const isMobile = useIsMobileViewport();

  const formattedEndDate = dayjs(subscriptionEndDate).format('MM/DD/YYYY');
  const isExpired = Date.now() > subscriptionEndDate;

  const description = useMemo(() => {
    if (isOwner) {
      // The subscription is in the PENDING state until the gift is sent
      if (
        subscriptionStatus === UserSubscriptionStatus.STARTED ||
        subscriptionStatus === UserSubscriptionStatus.PENDING
      ) {
        return `This project is included in your subscription, which is active until ${formattedEndDate}.`;
      }
      if (subscriptionStatus === UserSubscriptionStatus.CANCELED && isExpired === true) {
        return `The subscription for this project ended on ${formattedEndDate}.`;
      }

      // Cancelled and not expired
      return `This project is included in your subscription, which is cancelled and will expire on ${formattedEndDate}.`;
    }

    // The subscription is in the PENDING state until the gift is sent
    if (
      subscriptionStatus === UserSubscriptionStatus.STARTED ||
      subscriptionStatus === UserSubscriptionStatus.PENDING
    ) {
      return `This project is in ${genitiveCase(ownerName)} subscription, which is active until ${formattedEndDate}.`;
    }
    if (subscriptionStatus === UserSubscriptionStatus.CANCELED && isExpired === true) {
      return `${genitiveCase(ownerName)} subscription for this project ended on ${formattedEndDate}.`;
    }

    // Cancelled and not expired
    return `This project is in ${genitiveCase(
      ownerName,
    )} subscription, which is canceled and will expire on ${formattedEndDate}.`;
  }, [formattedEndDate, isExpired, isOwner, ownerName, subscriptionStatus]);

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Ownership " columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <RMText type="sans" size="s" color="on-surface-secondary">
          {description}
        </RMText>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
